import React from "react";
import {Redirect, Route, Switch} from "react-router";
import {useSelector} from "react-redux";
import {useLocation, matchPath} from "react-router-dom";
import Error404 from "./Pages/404";
import Login from "./Auth/Login";
import Register from "./Auth/Register";
import ForgotPasswordPage from "./Auth/ForgotPassword";
import VerifyUser from "./Auth/VerifyUser";
import Dashboard from "./Dashboard/index";
import Draws from "./Draws";
import Lotteries from "./Lotteries";
import Users from "./CRM";
import Reports from "./Reports";
import ViewReport from "./Reports/ViewReport";
import SMSLog from "./SMS";
import ContactSms from "./ContactSMS";
import Profile from "./Auth/Profile";

const RestrictedRoute = ({component: Component, ...rest}) => {
  const {authUser} = useSelector(({auth}) => auth);

  return (
    <Route
      {...rest}
      render={props =>
        authUser ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/signin",
              state: {from: props.location}
            }}
          />
        )}
    />
  );
};

const Routes = () => {
  const {authUser, verifiedUser} = useSelector(({auth}) => auth);
  const counter = useSelector(state => {
    return state.counter;
  });
  const location = useLocation();
  if (location.pathname === "/logout") {
    return <Redirect to={"/signin"} />;
  }
  if (authUser && verifiedUser) {
    if (
      location.pathname === "" ||
      location.pathname === "/" ||
      location.pathname === "/signin" ||
      location.pathname === "/signup" ||
      location.pathname === "/verify-account" ||
      matchPath(location.pathname, {
        path: "/signin/:id",
        exact: true,
        strict: false
      })
    ) {
      if(localStorage.getItem("role") != "dialog"){
        return <Redirect to={"/dashboard"} />;
      }else{
        return <Redirect to={"/users"} />;
      }
    }
  } else if (
    authUser &&
    !verifiedUser &&
    location.pathname !== "/verify-account"
  ) {
    return <Redirect to={"/verify-account"} />;
  } else if (
    !authUser &&
    (location.pathname === "" || location.pathname === "/")
  ) {
    return <Redirect to={"/signin"} />;
  }

  return (
    <React.Fragment>
      <Switch>
        <Route path="/signin" component={Login} />
        <Route exact path="/signin/:id" component={Login} />
        
        <Route path="/signup" component={Register} />
        <Route path="/forgot-password" component={ForgotPasswordPage} />
        <Route path="/verify-account" component={VerifyUser} />
        <RestrictedRoute path="/dashboard" component={Dashboard} />
        <RestrictedRoute path="/draws" component={Draws} />
        <RestrictedRoute path="/lotteries" component={Lotteries} />
        <RestrictedRoute exact path="/users" component={Users} />
        <RestrictedRoute exact path="/users/:userId" component={Profile} />
        <RestrictedRoute exact path="/reports" component={Reports} />
        <RestrictedRoute exact path="/reports/:status" component={ViewReport} />
        <RestrictedRoute exact path="/sms" component={SMSLog} />

        <RestrictedRoute exact path="/contactsms" component={ContactSms} />
        <Route component={Error404} />
      </Switch>
    </React.Fragment>
  );
};

export default Routes;
