import * as React from "react";
import { DataGrid } from "@material-ui/data-grid";
import { Box, IconButton, Typography } from "@material-ui/core";
import { RefreshSharp } from "@material-ui/icons";
import CustomGridPanel from "./CustomGridPanel";

const DataTable = ({ title, showToolbar, onRefresh, ...props }) => {
  const GridToolbar = ({}) => {
    return (
      <Box display="flex" alignItems="center">
        <Box flex="1" p={2}>
          <Typography>{title}</Typography>
        </Box>
        <Box display="flex" alignItems="center" justifyItems="flex-end">
          {onRefresh !== null && (
            <IconButton onClick={onRefresh}>
              <RefreshSharp />
            </IconButton>
          )}
        </Box>
      </Box>
    );
  };

  let components = {
    Panel: CustomGridPanel
  };

  if(showToolbar){
    components = {
      ...components,
      Toolbar: GridToolbar,
    }
  }

  return (
    <DataGrid
      {...props}
      components={components}
    />
  );
};

export default DataTable;
