const filterEmpty = val => {
  if(val === 0) return val;
  return val || "";
}

export function transformInToFormObject(data) {
  let formData = new FormData();
  for (let key in data) {
    if (Array.isArray(data[key])) {
      data[key].forEach((obj, index) => {
        let keyList = Object.keys(obj);
        keyList.forEach((keyItem) => {
          let keyName = [key, "[", index, "]", "[", keyItem, "]"].join("");
          formData.append(keyName, filterEmpty(obj[keyItem]));
        });
      });
    } else if (typeof data[key] === "object") {
      for (let innerKey in data[key]) {
        formData.append(`${key}.${innerKey}`, filterEmpty(data[key][innerKey]));
      }
    } else {
      formData.append(key, filterEmpty(data[key]));
    }
  }
  return formData;
}

export function toFixed(value, fractions = 2) {
  return (value || 0).toFixed(fractions);
}

export function toTicketNumber(ticket) {
  const keys = ["n1", "n2", "n3", "n4", "n5", "n6", "n7", "n8", "n9", "n10"];
  return Object.keys(ticket)
    .filter((key) => keys.includes(key))
    .map((key) => ticket[key])
    .filter((val) => val !== null && val !== undefined)
    .join(" ");
}
