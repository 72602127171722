import { Box, fade, IconButton } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { Grid, Loader, TextField } from "app/uielements";
import useAxios from "app/hooks/useAxios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { makeStyles } from "@material-ui/core/styles";
import CmtCardHeader from "@coremat/CmtCard/CmtCardHeader";
import CmtCard from "@coremat/CmtCard";
import CmtCardContent from "@coremat/CmtCard/CmtCardContent";
import IntlMessages from "@jumbo/utils/IntlMessages";
const useStyles = makeStyles((theme) => ({
  error: {
    border: "1px solid red",
  },
  cell: {
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    width: "10ch",
  },
  cardRoot: {
    position: "relative",
    "& .Cmt-card-content": {
      padding: 0,
      paddingBottom: 24,
    },
  },
  scrollbarRoot: {
    height: 280,
  },
  badgeRoot: {
    fontSize: 14,
    letterSpacing: 0.25,
    backgroundColor: fade(theme.palette.warning.main, 0.15),
    color: theme.palette.warning.main,
    padding: "2px 10px",
    borderRadius: 30,
  },
}));

const Cells = ({ id, search }) => {
  const [cells, setCells] = useState([]);
  const [loading, setLoading] = useState(false);
  const axios = useAxios();
  const classes = useStyles();
  const { register, handleSubmit } = useForm();
  useEffect(() => {
    let active = true;
    setCells([]);
    setLoading(true);
    (async () => {
      try {
        const response = await axios.get("/api/lottery/number/format/" + id);
        setLoading(false);
        const format = response.data.format;

        if (!active) {
          return;
        }

        if (format && format.original && format.original.errors === "1") {
          setCells([]);
        } else {
          setCells(
            Object.keys(format).map((key) => ({
              value: key,
              label: format[key],
            }))
          );
        }
      } catch (error) {
        setLoading(false);
      }
    })();

    return () => {
      active = false;
    };
  }, [id]);

  return (
    <CmtCard className={classes.cardRoot}>
      <CmtCardHeader title={<IntlMessages id="user.purchase.format.title" />}>
        <Box component="span" className={classes.badgeRoot}>
          <IntlMessages id="user.purchase.format.badge" />
        </Box>
      </CmtCardHeader>
      <CmtCardContent>
        {loading && <Loader />}
        <Grid container>
          <Grid container item xs={10}>
            {cells.map((cell) => cell.label == "special"?(
                    
              <TextField
                className={classes.cell}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                inputProps={{ maxLength: 7 }}
                {...register(cell.label)}
                name={cell.label}
                label={cell.label.toUpperCase()}
              />
              
            ):
            (
              <TextField
                className={classes.cell}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                inputProps={{ maxLength: 3 }}
                {...register(cell.label)}
                name={cell.label}
                label={cell.label.toUpperCase()}
              />
       
            )
            )}
          </Grid>
          <Grid item xs={2}>
            <IconButton onClick={handleSubmit(search)}>
              <Search />
            </IconButton>
          </Grid>
        </Grid>
      </CmtCardContent>
    </CmtCard>
  );
};

export default Cells;
