import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    minHeight: 150,
    marginBottom: 8,
  },
  type: {
    marginTop: 8,
  },
  title: {
    fontSize: 30,
  },
  total: {
    marginBottom: 8,
    marginTop: 12,
  },
  cardContent: { textAlign: "center" },
});

const BasicInfoCard = ({ cardObj }) => {
  const classes = useStyles();

  return (
    <Card className={classes.root} variant="outlined">
      <CardContent className={classes.cardContent}>
        <Typography
          className={classes.title}
          color="textSecondary"
          gutterBottom
          variant="h1"
          component="h1"
        >
          {cardObj.newValue}
        </Typography>
        <Typography className={classes.type} variant="h5" component="h2">
          {cardObj.type}
        </Typography>
        <Typography className={classes.total} color="textSecondary">
          {cardObj.total}
        </Typography>
      </CardContent>
    </Card>
  );
};

BasicInfoCard.propTypes = {
  cardObj: PropTypes.object.isRequired,
};

export default BasicInfoCard;
