import {Box, fade} from "@material-ui/core";
import React, {useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {DataGrid} from "@material-ui/data-grid";
import useData from "./SelectTickets.state";
import CmtCard from "@coremat/CmtCard";
import CmtCardHeader from "@coremat/CmtCard/CmtCardHeader";
import IntlMessages from "@jumbo/utils/IntlMessages";
import CmtCardContent from "@coremat/CmtCard/CmtCardContent";

const useStyles = makeStyles((theme) => ({
    ticketList: {
        maxHeight: 500, minHeight: 400, overflow: "scroll",
    }, cardRoot: {
        position: "relative", "& .Cmt-card-content": {
            padding: 0, paddingBottom: 24,
        },
    }, scrollbarRoot: {
        height: 280,
    }, badgeRoot: {
        fontSize: 14,
        letterSpacing: 0.25,
        backgroundColor: fade(theme.palette.warning.main, 0.15),
        color: theme.palette.warning.main,
        padding: "2px 10px",
        borderRadius: 30,
    },
}));

const SelectTickets = ({searchFields, id, setSelected, selected, draw, isCheckAnywhere}) => {
    const {
        rows,
        total,
        loading,
        pageSize,
        handlePageChange,
        handlePageCountChange,
        setId,
        setSearchFields,
        setIsCheckAnywhere,
        columns,
        selectionModel,
        handleSelection,
        setPage,
        unavailableTickets
    } = useData({setSelected, selected, draw});
    const classes = useStyles();

    useEffect(() => {
        setIsCheckAnywhere(isCheckAnywhere)
        setPage(0)
        setId(id);
        setSearchFields(searchFields);
    }, [searchFields, id]);

    return (<CmtCard className={classes.cardRoot}>
        <CmtCardHeader title={<IntlMessages id="user.purchase.select.title"/>}>
            <Box component="span" className={classes.badgeRoot}>
                <IntlMessages id="user.purchase.select.badge"/>
            </Box>
        </CmtCardHeader>
        <CmtCardContent className={classes.scrollbarRoot}>
            <DataGrid
                rows={rows}
                columns={columns}
                pagination
                getRowId={(row) => row.ticket_id}
                pageSize={pageSize}
                rowsPerPageOptions={[10, 20, 50, 100]}
                rowCount={total}
                paginationMode="server"
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageCountChange}
                onSelectionModelChange={handleSelection}
                selectionModel={selectionModel}
                checkboxSelection
                loading={loading}
                isRowSelectable={(params) => {
                    if (!loading) {
                        const isUnavailable = unavailableTickets.includes(params.row.ticket_id)
                        const isSelected = selectionModel.includes(params.row.ticket_id);
                        if (isSelected && isUnavailable) {
                            return true;
                        }
                        return !isUnavailable;
                    }
                }}
            />

        </CmtCardContent>
    </CmtCard>);
};

export default SelectTickets;