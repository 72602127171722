import React from "react";
import Hidden from "@material-ui/core/Hidden";
import {Box} from "@material-ui/core";
import {NavLink} from "react-router-dom";
import CmtImage from "../../../../@coremat/CmtImage";
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  logo: {
    width: 55
  }
}));

const Logo = ({color, logoClass, type, ...props}) => {
  const classes = useStyles();
  const logoUrl = type === "title" ? "/images/name.png" : "/images/logo.png";
  const logoSymbolUrl =
    color === "white"
      ? "/images/logo-white-symbol.png"
      : "/images/logo-symbol.png";

  return (
    <Box className="pointer" {...props}>
      <Hidden xsDown>
        <NavLink to="/">
          <CmtImage className={classes.logo} src={logoUrl} alt="logo" />
        </NavLink>
      </Hidden>
      <Hidden smUp>
        <NavLink to="/">
          <CmtImage src={logoSymbolUrl} alt="logo" />
        </NavLink>  
      </Hidden>
    </Box>
  );
};

export default Logo;
