import React from "react";
import useUsers from "./UserList.state";
import { useIntl } from "react-intl";
import DataGrid from "app/uielements/Table/DataGrid";
import ContextMenu from "app/uielements/ContextMenu";

import TopupWallet from "../Topup";
import TransactionList from "../Transactions";
import PurchaseTicket from "../PurchaseTicket";

import moment from "moment";
import usePermissions from "app/hooks/usePermissions";
import useStyles from "./UserList.style";
import { Paper } from "@material-ui/core";
import TicketList from "../Tickets";
import IntlMessages from "@jumbo/utils/IntlMessages";
import BillRecon from "../BillRecon";
import ViewSubscriptions from "../Subscriptions/View";
import AddSubscription from "../Subscriptions/Add";
import EditUser from "../EditUser";
import UpdateInsurance from "../UpdateInsurance";
import SearchForm from "./SearchForm";

const UserList = ({ lotteryList, total, setTotal }) => {
  const {
    rows,
    loading,
    pageSize,
    id,
    setSelected,
    handlePageChange,
    handlePageCountChange,
    setId,
    topupOpen,
    setTopupOpen,
    transactionOpen,
    setTransactionOpen,
    purchaseOpen,
    setPurchaseOpen,
    handleSortModelChange,
    sortModel,
    handleFilterChange,
    ticketsOpen,
    setTicketsOpen,
    selected,
    handleRefresh,
    billOpen,
    setBillOpen,
    addSubscriptionOpen,
    setAddSubscriptionOpen,
    viewSubscriptionOpen,
    setViewSubscriptionOpen,
    deleteUser,
    setEditOpen,
    editOpen,
    setUpdateInsurance,
    updateInsurance,
    updateRow,
    onSearch,
    onReset,
    form,
    setValue,
  } = useUsers({ total, setTotal });

  const classes = useStyles();
  const intl = useIntl();
  const { applyPermissions } = usePermissions();
  const format = (id) =>
    intl.formatMessage({
      id: `user.list.${id}`,
    });

  const handleMenu = async (evt, option, data) => {
    if (id !== evt) {
      setId(evt);
      setSelected(data);
    }

    if (option === format("options.topup")) {
      setTopupOpen(true);
    }

    if (option === format("options.transactions")) {
      setTransactionOpen(true);
    }

    if (option === format("options.purchase")) {
      setPurchaseOpen(true);
    }

    if (option === format("options.view_tickets")) {
      setTicketsOpen(true);
    }

    if (option === format("options.bill_recon")) {
      setBillOpen(true);
    }

    if (option === format("options.add_subscription")) {
      setAddSubscriptionOpen(true);
    }

    if (option === format("options.view_subscriptions")) {
      setViewSubscriptionOpen(true);
    }

    if (option === format("options.edit")) {
      setEditOpen(true);
    }

    if (option === format("options.updateinsurance")) {
      setUpdateInsurance(true);
    }

    if (option === format("options.delete")) {
      await deleteUser(data);
    }
  };

  const handleClose = () => {
    setTopupOpen(false);
    setTransactionOpen(false);
    setPurchaseOpen(false);
    setTicketsOpen(false);
    setBillOpen(false);
    setAddSubscriptionOpen(false);
    setViewSubscriptionOpen(false);
    setEditOpen(false);
    setUpdateInsurance(false);
  };

  const options = applyPermissions([
    (localStorage.getItem("role") != "dialog") ? { key: "user.update", value: format("options.edit")}: { key: null, value: null},
    (localStorage.getItem("role") != "dialog") ? { key: "transaction.add", value: format("options.topup") }: { key: null, value: null },
    (localStorage.getItem("role") != "dialog") ? { key: "transactions.view", value: format("options.transactions") } : { key: null, value: null },
    { key: "tickets.buy", value: format("options.purchase") },
    { key: "usertickets.view", value: format("options.view_tickets") },
    (localStorage.getItem("role") != "dialog") ? { key: "tickets.buy", value: format("options.bill_recon") }: { key: null, value: null },
    { key: "subscription.create", value: format("options.add_subscription") },
    { key: "subscription.view", value: format("options.view_subscriptions") },
    (localStorage.getItem("role") != "dialog") ? { key: "user.delete", value: format("options.delete") }: { key: null, value: null },
    (localStorage.getItem("role") != "dialog") ? { key: "user.ceylincoregister", value: format("options.updateinsurance") }: { key: null, value: null },
  ]);
  

  const columns = [
    {
      field: "fname",
      headerName: format("fname"),
      flex: 1,
    },
    { field: "lname", headerName: format("lname"), flex: 1 },
    { field: "email", headerName: format("email"), flex: 1.5 },
    { field: "telephone", headerName: format("telephone"), flex: 1 },
    {
      field: "created_at",
      headerName: format("created_at"),
      flex: 1,
      valueFormatter: (params) => moment(params.value).format("MM/DD/yyyy"),
    },
    {
      field: "updated_at",
      headerName: format("updated_at"),
      flex: 1,
      valueFormatter: (params) => moment(params.value).format("MM/DD/yyyy"),
    },
    { field: "status", headerName: format("status"), flex: 1 },
    { field: "nicno", headerName: format("nicno"), flex: 1 },
    {
      field: "id",
      headerName: format("action"),
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <ContextMenu
          options={options}
          onClick={(evt) => handleMenu(params.value, evt, params.row)}
        />
      ),
      flex: 1,
    },
  ];

  return (
    <React.Fragment>
      <SearchForm
        className={classes.searchForm}
        form={form}
        onSearch={onSearch}
        onReset={onReset}
        setValue={setValue}
        title={intl.formatMessage({ id: "user.list.search.title" })}
      />
      <Paper className={classes.grid}>
        <DataGrid
          title={<IntlMessages id="user.list.title" />}
          rows={rows}
          showToolbar
          columns={columns}
          pagination
          pageSize={pageSize}
          rowsPerPageOptions={[10, 20, 50, 100]}
          rowCount={total}
          paginationMode="server"
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageCountChange}
          sortingMode="server"
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
          filterMode="server"
          onFilterModelChange={handleFilterChange}
          loading={loading}
          onRefresh={handleRefresh}
        />
      </Paper>

      {topupOpen && (
        <TopupWallet id={id} open={topupOpen} handleClose={handleClose} />
      )}
      {purchaseOpen && (
        <PurchaseTicket
          id={id}
          open={purchaseOpen}
          handleClose={handleClose}
          lotteryList={lotteryList}
        />
      )}
      {transactionOpen && (
        <TransactionList
          id={id}
          open={transactionOpen}
          handleClose={handleClose}
        />
      )}
      {ticketsOpen && (
        <TicketList
          id={id}
          open={ticketsOpen}
          handleClose={handleClose}
          lotteries={lotteryList}
          user={selected}
        />
      )}
      {billOpen && (
        <BillRecon
          id={id}
          open={billOpen}
          handleClose={handleClose}
          lotteries={lotteryList}
          user={selected}
        />
      )}
      {viewSubscriptionOpen && (
        <ViewSubscriptions
          id={id}
          open={viewSubscriptionOpen}
          handleClose={handleClose}
          lotteries={lotteryList}
          user={selected}
        />
      )}
      {addSubscriptionOpen && (
        <AddSubscription
          id={id}
          open={addSubscriptionOpen}
          handleClose={handleClose}
          lotteries={lotteryList}
          user={selected}
        />
      )}
      {editOpen && (
        <EditUser
          id={id}
          open={editOpen}
          handleClose={handleClose}
          lotteries={lotteryList}
          user={selected}
          updateRow={updateRow}
        />
      )}
       {updateInsurance && (
        <UpdateInsurance
          id={id}
          open={updateInsurance}
          handleClose={handleClose}
          lotteries={lotteryList}
          user={selected}
          updateRow={updateRow}
        />
      )}
    </React.Fragment>
  );
};

export default UserList;
