import { darken, lighten, makeStyles } from "@material-ui/core/styles";
import { getThemePaletteMode } from "@material-ui/data-grid";

const useStyles = makeStyles((theme) => {
  const getBackgroundColor = (color) =>
    getThemePaletteMode(theme.palette) === "dark"
      ? darken(color, 0.6)
      : lighten(color, 0.6);

  const getHoverBackgroundColor = (color) =>
    getThemePaletteMode(theme.palette) === "dark"
      ? darken(color, 0.5)
      : lighten(color, 0.5);
  return {
    grid: {
      height: "640px",
      padding: theme.spacing(2),
      width: "100%",
      "& .with-winning--present": {
        backgroundColor: getBackgroundColor(theme.palette.info.main),
        "&:hover": {
          backgroundColor: getHoverBackgroundColor(theme.palette.info.main),
        },
      }
    },
  };
});

export default useStyles;
