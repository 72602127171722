import React, { useState, useEffect } from "react";
import useAxios from "../../../hooks/useAxios";
import throttle from "lodash/throttle";
import { useIntl } from "react-intl";
import moment from "moment";
import { confirm } from "app/hooks/useAlerts";
import showAlert from "app/hooks/useAlerts";

const useData = ({ lotteries }) => {
  const axios = useAxios();

  const [id, setId] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(null);
  const [editOpen, setEditOpen] = useState(false);

  const [filterModel, setFilterModel] = React.useState({ items: [] });

  const [sortModel, setSortModel] = React.useState([
    { field: "id", sort: "asc" },
  ]);

  const formatDate = (params) =>
    moment(params.value).isValid()
      ? moment(params.value).format("yyyy-MM-DD HH:mm")
      : "";

  const getLotteryName = (params) => {
    return (lotteries.find((l) => l.value === params.value) || {}).label;
  };

  const updateRow = (data) => {
    const newRows = [...rows];

    const row = newRows.find((r) => r.id === data.id);
    Object.keys(row).forEach((key) => (row[key] = data[key]));

    setRows(newRows);
  };

  const toggleStatus = async (subscriptionId, row) => {
    try {
      let url = "";
      setLoading(true);
      if (!row.status) {
        url = `/api/user/subscribe/${subscriptionId}`;
      } else {
        url = `/api/user/unsubscribe/${subscriptionId}`;
      }
      const response = await axios.get(url);
      setLoading(false);

      if (response.status === 200) {
        updateRow({
          ...row,
          status: response.data.data.status,
        });
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handleSortModelChange = (params) => {
    if (params.sortModel !== sortModel) {
      setSortModel(params.sortModel);
      setPage(0);
    }
  };

  const handleFilter = (params) => {
    setFilterModel(params.filterModel);
    setPage(0);
  };

  const handleFilterChange = React.useCallback(throttle(handleFilter, 500), []);

  const handlePageChange = (params) => {
    setPage(params.page);
  };

  const handlePageCountChange = (params) => {
    setPageSize(params.pageSize);
    setPage(0);
  };

  useEffect(() => {
    let active = true;

    (async () => {
      if (id < 1) return;
      setLoading(true);

      const body = {
        page_count: pageSize,
        user_id: id,
        sortModel,
        filterModel: {
          items: filterModel.items
            .filter((i) => i.value)
            .map((i) => ({
              ...i,
              columnField: `tickets.${i.columnField}`,
            })),
        },
      };

      try {
        const response = await axios.get(
          `/api/user/subscription/index/${id}?page=` + (page + 1),
          body
        );
        const newRows = response.data.data;
        const totalValue = response.data.total;

        if (!active) {
          return;
        }

        setRows(newRows);
        setTotal(totalValue);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    })();

    return () => {
      active = false;
    };
  }, [page, pageSize, id, sortModel, filterModel]);

  const editItem = async (id, subscription) => {
    setSelected(subscription);
    setEditOpen(true);
  };

  const deleteItem = async (id, subscription) => {
    setSelected(subscription);
    confirm({
      title: "Delete Subscription",
      text: "Are you sure want to delete the subscription?",
      successCallback: async () => {
        setLoading(true);
        try {
          const response = await axios.get(
            `/api/user/subscription/delete/${id}`
          );
          setLoading(false);
          if (response.status === 200) {
            setTotal((total) => total - 1);
            setRows((rows) => rows.filter((r) => r.id !== id));
          }
        } catch (error) {
          setLoading(false);
        }
      },
    });
  };

  const handleEditClose = (item) => {
    setEditOpen(false);
    setRows((rows) => {
      return rows.map((row) => {
        if (row.id === item.id) {
          return item;
        }

        return row;
      });
    });
  };

  return {
    rows,
    total,
    loading,
    pageSize,
    handlePageChange,
    handlePageCountChange,
    setRows,
    setId,
    handleSortModelChange,
    sortModel,
    handleFilterChange,
    toggleStatus,
    getLotteryName,
    formatDate,
    editItem,
    deleteItem,
    selected,
    editOpen,
    handleEditClose,
  };
};

export default useData;
