
import React, { useState, useEffect } from 'react';
import CmtCard from '@coremat/CmtCard';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import IntlMessages from '@jumbo/utils/IntlMessages';
import { Button, IconButton, Typography, Dialog, DialogTitle, DialogContent, TextField, Grid, Select, FormControl, Box, InputLabel, MenuItem } from '@material-ui/core';
import DataGrid from 'app/uielements/Table/DataGrid';
import { Send, Close } from '@material-ui/icons';
import { useIntl } from 'react-intl';
import useAxios from "app/hooks/useAxios";
import showAlert from "app/hooks/useAlerts";
import { color } from '@storybook/addon-knobs';

const Results = ({
  rows,
  title,
  className,
  titleProps,
  total,
  pageSize,
  handlePageChange,
  handlePageCountChange,
  handleSortModelChange,
  sortModel,
  handleFilterChange,
  handleRefresh,
  
}) => {
  const axios = useAxios();
  const [loading, setLoading] = useState(false);
  const intl = useIntl();
  const [message, setMessage] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [status, setStatus] = useState('');
  const [remark, setRemark] = useState('');
  const [showStatus, setShowStatus] = useState();
  const[showRemark,setShowRemark]=useState();

const [updatedRows, setUpdatedRows] = useState([]);
const [clickedRowPosition, setClickedRowPosition] = useState({});
const [clickedRowMessage, setClickedRowMessage] = useState('');

// const handleRowClick = (params, event) => {
//   const rowBounds = event.currentTarget.getBoundingClientRect();
//   setClickedRowPosition({
//     top: rowBounds.bottom + window.scrollY + 5, 
//     left: rowBounds.left + window.scrollX,
//   });
//   setClickedRowMessage(params.row.message);
// };
  // const handleChange = (e) => {
  //   e.preventDefault();
  //   setStatus(e.target.value);
  // };
  const handleChange = (e) => {
    e.preventDefault();
    const status = e.target.value;
    setStatus(status);
    setShowStatus(status);
    setShowRemark(remark);
    localStorage.setItem('showStatus', status);
    localStorage.setItem('showRemark', remark);
  };
  console.log('clickedRowMessage',clickedRowMessage)
  const handleRemarkChange = (e) => {
    setRemark(e.target.value);
  };

  // const openModal = (data) => {
  //   setMessage(data);
  //   setModalOpen(true);
  // };
  const openModal = (data) => {
    setMessage(data);
    setStatus(data.current_status);
    setRemark(data.remarks);
    setShowStatus(localStorage.getItem(showStatus));
    setShowRemark(localStorage.getItem(showRemark));
    setModalOpen(true);
  };
  

  const closeModal = () => {
    setMessage('');
    setModalOpen(false);
  };

  const contactStatus = async (id, status, remark) => {
    setLoading(true);
    try {
      const response = await axios.post(`/api/contactUsMessages/update/${id}`, {
        id: id,
        current_status: status,
        remarks: remark,
      });


      setLoading(false);
      if (response.status === 200) {
        showAlert('success', 'status added');
        const updatedRow = rows.find((row) => row.id === id);
      if (updatedRow) {
       
        updatedRow.current_status = status;
        updatedRow.remarks = remark;


        setUpdatedRows([...updatedRows, updatedRow]);
      }
closeModal(true);
// handlePageChange(0);

// window.location.reload(true)
        
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
      showAlert('error', 'error.');
    }
  };

  const statusColor = (status) => {
    if (status === 'IN PROGRESS') {
      return 'rgb(31, 81, 255)';
    } else if (status === 'ON HOLD') {
      return 'rgb(175, 126, 46)';
    } else if (status === 'CLOSED') {
      return 'rgb(107, 201, 80)';
    } else {
      return 'rgb(229, 0, 0)';
    }
  };

  const columns = [
    
    {
      field: 'created_at',
      // headerName: intl.formatMessage({ id: 'Contact.sms.create_at' }),
      headerName:'Create at',
      flex: 1.3,
      
    },
    {
      field: 'mobile_no',
      // headerName: intl.formatMessage({ id: 'Contact.sms.mobile_no' }),
      headerName:'Mobile No',
      flex: 1.3,
    },
    

    {
      field: 'name',
      // headerName: intl.formatMessage({ id: 'Contact.sms.name' }),
      headerName:'Name',
      flex: 1.3,
    },
   
   
    
    {
      field: 'current_status',
      // headerName: intl.formatMessage({ id: 'Status' }),
      headerName:'Status',
      flex: 1.3,
      renderCell: (params) => (
        <Typography variant="subtitle2" style={{ display: 'flex', whiteSpace: 'pre-line', fontWeight: "bold", fontSize: '15px', marginBottom: '6px',color:"white"}}>
        <Button variant="contained" color="primary" size="small" style={{ fontSize: '9px' ,background: statusColor(params.value) }}>
        {params.value}
          </Button>
          
          {/* {params.value} */}
        </Typography>
      ),
    },
    {
      field: 'messages', 
      headerName: 'View Message', 
      flex: 2,
      renderCell: (params) => (
        <div style={{ maxHeight: '100px', overflowY: 'auto' }}> 
        <Typography variant="subtitle2" style={{ display: 'flex', whiteSpace: 'pre-line', fontWeight: 'bold', fontSize: '9px', marginBottom: '6px' ,marginTop:"15px"}}>
          {params.row.message}
        </Typography>
      </div>
      ),
    },
    {
      field: 'message',
      headerName: 'Action',
      flex: 1.3,
      renderCell: (params) => (
        <Typography variant="subtitle2" style={{ display: 'flex', whiteSpace: 'pre-line', fontSize: '10px', marginBottom: '6px' }}>
          
          <Button variant="contained" color="primary" size="small" onClick={() => openModal(params.row)} style={{ fontSize: '9px' }}>
            View Message
          </Button>
          
        </Typography>
        
      ),
    },
   
   
  ];

  return (
    <CmtCard className={className}>
      <CmtCardHeader />
      {rows.length > 0 && (
        <CmtCardContent style={{ height: 655 }}>
          {clickedRowMessage}
          <DataGrid
          rowHeight={100}
          showToolbar
            rows={rows}
            columns={columns}
            pagination
            pageSize={pageSize}
            rowsPerPageOptions={[10, 20, 50, 100]}
            rowCount={total}
            paginationMode="server"
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageCountChange}
            sortingMode="server"
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            filterMode="server"
            onFilterModelChange={handleFilterChange}
            onRefresh={handleRefresh}
            // onRowClick={(params) => {
             
            //   alert(`${(params.row.message)}`);
            // }}
              // onRowClick={handleRowClick}
              
          />
        
        </CmtCardContent>
      )}
      <Dialog open={modalOpen} fullWidth>
        {message && (
          <>
            <IconButton size="small" onClick={closeModal} style={{ width: 10, marginLeft: '570px' }}>
              <Close color="error" />
            </IconButton>
            <DialogTitle>Message</DialogTitle>
            <CmtCard className={className} style={{ width: 500, marginLeft: '40px', marginBottom: '15px' }}>
              <Box>
                <FormControl style={{ width: 200, margin: "8px" }}>
                  <InputLabel id="demo-simple-select-label">Status</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={status}
                    label="Status"
                    onChange={handleChange} 
                  >
                  <MenuItem value={"PENDING"}>PENDING</MenuItem>
                    <MenuItem value={"IN PROGRESS"}>IN PROGRESS</MenuItem>
                    <MenuItem value={"ON HOLD"}>ON HOLD</MenuItem>
                    <MenuItem value={"CLOSED"}>CLOSED</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <CmtCardContent>
                <DialogContent style={{ height: 500 }}>
                  <Typography>Mobile No: {message.mobile_no}</Typography>
                  
                  <Typography>Time: {message.created_at}</Typography>
                  <Typography>Name: {message.name}</Typography>
                  <CmtCard className={className} style={{ width: 400, marginTop: '9px', borderStyle: 'ridge', borderRadius: '7px', height: 200 }}>
  <Typography style={{ margin: '6px' }}>Message:</Typography>
  <div style={{ overflow: 'auto', maxHeight: '150px' }}>
    <Typography style={{ margin: '6px', fontWeight: 'bold', fontSize: '11px' }}>
      {message.message}
    </Typography>
  </div>
</CmtCard>

                  <Grid container spacing={2} alignItems="center" style={{marginTop:"6px"}}>
  <Grid item>
    <TextField value={remark} onChange={handleRemarkChange} variant="outlined" id="outlined-multiline-flexible" label="Remark..." multiline maxRows={4} style={{ width: '300px' }} />
  </Grid>
  <Grid item>
    <Button variant="contained"  color="primary" size="small" onClick={() => contactStatus(message.id, status, remark)}>Save</Button>
  </Grid>
</Grid>

                </DialogContent>
              </CmtCardContent>
            </CmtCard>
          </>
        )}
      </Dialog>
    </CmtCard>
  );
};

export default Results;
